import type {Role} from "@/types/roles";
import {getRolesObjectKey} from "@/types/roles";
import type {TokenPayload} from "@/types/token";

export const useAuthorizationStore = defineStore("authorizationStore", () => {
    const tokenCookieName = useRuntimeConfig().public.tokenCookieName;
    const token = useCookie(tokenCookieName);
    const refreshToken = () => {
        token.value = useCookie(tokenCookieName).value;
    };

    const getTokenPayload = computed(() => {
        if (!token.value) return;
        const tokenPayloadBase64 = token.value.split(".").at(1);
        if (!tokenPayloadBase64) return;
        const tokenPayloadString = fromBase64(tokenPayloadBase64);
        return JSON.parse(tokenPayloadString) as TokenPayload;
    });

    const getUserRoles = computed<Partial<Record<Role, boolean>>>(() => {
        if (getTokenPayload.value == null) {
            return getRolesObjectKey("UNAUTHORIZED");
        }

        const roles = getTokenPayload.value.userData.roles;
        const rolesEntries = roles.map((role) => [role, true] as [Role, boolean]);
        return {
            ...Object.fromEntries(rolesEntries),
            ...getRolesObjectKey("AUTHORIZED"),
        };
    });

    return {
        getTokenPayload,
        getUserRoles,
        token,
        refreshToken,
    };
});
