import { default as _91_46_46_46space_93wNoP4Q1zkPMeta } from "/application/pages/applications/story-vue-r/[...space].vue?macro=true";
import { default as indexA2chzpCTckMeta } from "/application/pages/applications/story-vue-r/index.vue?macro=true";
import { default as indexUEHw9xstOJMeta } from "/application/pages/index.vue?macro=true";
import { default as loginFLbOmRceC3Meta } from "/application/pages/login.vue?macro=true";
import { default as logouti6fihoMoQpMeta } from "/application/pages/logout.vue?macro=true";
import { default as indexGR7VAFmXn8Meta } from "/application/pages/management/applications/[applicationId]/index.vue?macro=true";
import { default as indexrWdcwKEV6pMeta } from "/application/pages/management/applications/index.vue?macro=true";
import { default as usersN9IZ3TW9xtMeta } from "/application/pages/management/users.vue?macro=true";
export default [
  {
    name: _91_46_46_46space_93wNoP4Q1zkPMeta?.name ?? "applications-story-vue-r-space",
    path: _91_46_46_46space_93wNoP4Q1zkPMeta?.path ?? "/applications/story-vue-r/:space(.*)*",
    meta: _91_46_46_46space_93wNoP4Q1zkPMeta || {},
    alias: _91_46_46_46space_93wNoP4Q1zkPMeta?.alias || [],
    redirect: _91_46_46_46space_93wNoP4Q1zkPMeta?.redirect,
    component: () => import("/application/pages/applications/story-vue-r/[...space].vue").then(m => m.default || m)
  },
  {
    name: indexA2chzpCTckMeta?.name ?? "applications-story-vue-r",
    path: indexA2chzpCTckMeta?.path ?? "/applications/story-vue-r",
    meta: indexA2chzpCTckMeta || {},
    alias: indexA2chzpCTckMeta?.alias || [],
    redirect: indexA2chzpCTckMeta?.redirect,
    component: () => import("/application/pages/applications/story-vue-r/index.vue").then(m => m.default || m)
  },
  {
    name: indexUEHw9xstOJMeta?.name ?? "index",
    path: indexUEHw9xstOJMeta?.path ?? "/",
    meta: indexUEHw9xstOJMeta || {},
    alias: indexUEHw9xstOJMeta?.alias || [],
    redirect: indexUEHw9xstOJMeta?.redirect,
    component: () => import("/application/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginFLbOmRceC3Meta?.name ?? "login",
    path: loginFLbOmRceC3Meta?.path ?? "/login",
    meta: loginFLbOmRceC3Meta || {},
    alias: loginFLbOmRceC3Meta?.alias || [],
    redirect: loginFLbOmRceC3Meta?.redirect,
    component: () => import("/application/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logouti6fihoMoQpMeta?.name ?? "logout",
    path: logouti6fihoMoQpMeta?.path ?? "/logout",
    meta: logouti6fihoMoQpMeta || {},
    alias: logouti6fihoMoQpMeta?.alias || [],
    redirect: logouti6fihoMoQpMeta?.redirect,
    component: () => import("/application/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: indexGR7VAFmXn8Meta?.name ?? "management-applications-applicationId",
    path: indexGR7VAFmXn8Meta?.path ?? "/management/applications/:applicationId()",
    meta: indexGR7VAFmXn8Meta || {},
    alias: indexGR7VAFmXn8Meta?.alias || [],
    redirect: indexGR7VAFmXn8Meta?.redirect,
    component: () => import("/application/pages/management/applications/[applicationId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexrWdcwKEV6pMeta?.name ?? "management-applications",
    path: indexrWdcwKEV6pMeta?.path ?? "/management/applications",
    meta: indexrWdcwKEV6pMeta || {},
    alias: indexrWdcwKEV6pMeta?.alias || [],
    redirect: indexrWdcwKEV6pMeta?.redirect,
    component: () => import("/application/pages/management/applications/index.vue").then(m => m.default || m)
  },
  {
    name: usersN9IZ3TW9xtMeta?.name ?? "management-users",
    path: usersN9IZ3TW9xtMeta?.path ?? "/management/users",
    meta: usersN9IZ3TW9xtMeta || {},
    alias: usersN9IZ3TW9xtMeta?.alias || [],
    redirect: usersN9IZ3TW9xtMeta?.redirect,
    component: () => import("/application/pages/management/users.vue").then(m => m.default || m)
  }
]